<template>
  <img v-if="logo" :src="logo" style="width: 13px" />

  <img v-else style="width: 13px" src="../../assets/images/empty_token.png" />
  <a
    class="hash-tag text-truncate myFnExpandBox_searchVal"
    style="margin-start: 0.2rem"
    href="#"
    @click.prevent="showContract(address)"
    >{{ tokenName ? tokenName : parseLongText(address) }} ({{ symbol }})</a
  >
</template>

<script>
import route from "../../router/index";
import { parseLongText } from "../../../src/assets/js/utils.js";
export default {
  props: {
    logo: "",
    address: "",
    tokenName: "",
    symbol: "",
  },
  methods: {
    showContract(contract) {
      route.push({
        path: "/TokenDetail",
        query: {
          c: contract,
        },
      });
    },
    parseLongText(str, start = 10, end = 6) {
      return parseLongText(str, start, end);
    },
  },
};
</script>
