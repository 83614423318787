<template>
  <!-- transaction 详情  -->
  <main id="content" role="main">
    <div class="container py-3 mn-b3">
      <div class="d-lg-flex justify-content-between align-items-center">
        <div class="mb-3 mb-lg-0">
          <h1 class="h4 mb-0">Transaction Details</h1>
        </div>
        <div class="d-flex flex-wrap mt-1 mt-md-0 flex-wrap ml-md-auto"></div>
      </div>
    </div>

    <div class="container space-bottom-2" v-loading="loading">
      <div class="card">
        <div
          class="
            card-header
            sticky-card-header
            d-flex
            justify-content-between
            p-0
          "
        >
          <ul
            class="nav nav-custom nav-borderless nav_tabs1"
            id="nav_tabs"
            role="tablist"
          >
            <li class="nav-item">
              <a
                class="nav-link active"
                id="home-tab"
                data-toggle="tab"
                href="#home"
                aria-controls="home"
                aria-selected="true"
                onclick="javascript:updatehash('');"
                >Overview</a
              >
            </li>
          </ul>
        </div>

        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div id="ContentPlaceHolder1_maintable" class="card-body">
              <div class="row align-items-center mt-1">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <i
                    class="
                      fal
                      fa-question-circle
                      text-secondary
                      d-none d-sm-inline-block
                      mr-1
                    "
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="A TxHash or transaction hash is a unique 66 characters identifier that is generated whenever a transaction is executed."
                  >
                  </i>
                  Transaction Hash:
                </div>
                <div class="col-md-9">
                  <span id="spanTxHash" class="mr-1">{{ hash }}</span>

                  <a
                    class="js-clipboard text-muted"
                    href="javascript:;"
                    data-toggle="tooltip"
                    title=""
                    data-content-target="#spanTxHash"
                    data-class-change-target="#spanTxHashLinkIcon"
                    data-success-text="Copied"
                    data-default-class="far fa-copy"
                    data-success-class="far fa-check-circle"
                  >
                    <span id="spanTxHashLinkIcon" class="far fa-copy"></span>
                  </a>
                </div>
              </div>

              <hr class="hr-space" />
              <div class="row align-items-center mn-3">
                <div
                  class="
                    col-auto col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <i
                    class="
                      fal
                      fa-question-circle
                      text-secondary
                      d-none d-sm-inline-block
                      mr-1
                    "
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="The status of the transaction."
                  ></i
                  >Status:
                </div>
                <div class="col col-md-9">
                  <span
                    v-if="transactionData.status == true"
                    class="u-label u-label--sm u-label--success rounded"
                    data-toggle="tooltip"
                    title=""
                  >
                    {{ getName("Common", "Success") }}</span
                  >
                  <span
                    v-else
                    class="u-label u-label--sm u-label--danger rounded"
                    style="color: red"
                    data-toggle="tooltip"
                    title=""
                  >
                    {{ getName("Common", "Failed") }}</span
                  >
                </div>
              </div>

              <hr class="hr-space" />

              <div class="row align-items-center">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <i
                    class="
                      fal
                      fa-question-circle
                      text-secondary
                      d-none d-sm-inline-block
                      mr-1
                    "
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="The number of the block in which the transaction was recorded. Block confirmation indicate how many blocks since the transaction is validated."
                  ></i
                  >Block:
                </div>
                <div class="col-md-9">
                  <a
                    href="#"
                    v-on:click.prevent="showBlock(transactionData.blockNumber)"
                    >{{ transactionData.blockNumber }}</a
                  >
                </div>
              </div>

              <hr class="hr-space" />

              <div class="row align-items-center">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <i
                    class="
                      fal
                      fa-question-circle
                      text-secondary
                      d-none d-sm-inline-block
                      mr-1
                    "
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="The date and time at which a transaction is validated."
                  ></i
                  >Timestamp:
                </div>
                <div class="col-md-9">
                  <span id="clock"></span
                  ><i class="far fa-clock small mr-1"></i> {{ showTime
                  }}{{ getName("Common", "ago") }} ({{
                    formatDate(transactionData.timestamp, "yyyy-MM-dd hh:mm:ss")
                  }}
                  )
                </div>
              </div>

              <hr class="hr-space" />

              <div class="row align-items-center">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <i
                    class="
                      fal
                      fa-question-circle
                      text-secondary
                      d-none d-sm-inline-block
                      mr-1
                    "
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="The sending party of the transaction (could be from a contract address)."
                  >
                  </i>
                  From:
                </div>
                <div class="col-md-9">
                  <ItemAddress
                    v-if="transactionData.fromInfo"
                    :address="transactionData.from"
                    :info="transactionData.fromInfo"
                    :longAddress="true"
                    style="margin-end: 0.3rem"
                  />

                  <span id="spanFromAdd" style="display: none">{{
                    transactionData.from
                  }}</span>
                  <a
                    class="js-clipboard text-muted"
                    href="javascript:;"
                    data-toggle="tooltip"
                    title=""
                    data-content-target="#spanFromAdd"
                    data-class-change-target="#fromAddressLinkIcon"
                    data-success-text="Copied"
                    data-default-class="far fa-copy"
                    data-success-class="far fa-check-circle"
                  >
                    <span id="fromAddressLinkIcon" class="far fa-copy"></span>
                  </a>
                </div>
              </div>

              <hr class="hr-space" />

              <div class="row">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <i
                    class="
                      fal
                      fa-question-circle
                      text-secondary
                      d-none d-sm-inline-block
                      mr-1
                    "
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="The receiving party of the transaction (could be a contract address)."
                  ></i
                  >To:
                </div>
                <div class="col-md-9">
                  <ItemAddress
                    v-if="transactionData.toInfo"
                    :address="transactionData.to"
                    :info="transactionData.toInfo"
                    :longAddress="true"
                    style="margin-end: 0.3rem"
                  />

                  <span id="spanToAdd" style="display: none">{{
                    transactionData.to
                  }}</span>
                  <a
                    class="js-clipboard text-muted ml-1"
                    href="javascript: ;"
                    data-toggle="tooltip"
                    title=""
                    data-content-target="#spanToAdd"
                    data-class-change-target="#spanToAddResult"
                    data-success-text="Copied"
                    data-default-class="far fa-copy"
                    data-success-class="far fa-check-circle"
                  >
                    <span id="spanToAddResult" class="far fa-copy"></span>
                  </a>
                </div>
              </div>

              <hr class="hr-space" />

              <!-- token transfers -->
              <div v-if="tokenTrxs.length > 0" class="row">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <i
                    class="
                      fal
                      fa-question-circle
                      text-secondary
                      d-none d-sm-inline-block
                      mr-1
                    "
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="List of token transferred in the transaction."
                  ></i
                  >Tokens Transferred:
                  <span class="badge badge-pill badge-secondary align-midle">{{
                    tokenTrxs.length
                  }}</span>
                </div>
                <div class="col-md-9">
                  <!-- inner transfers -->
                  <el-scrollbar max-height="200px">
                    <div
                      v-for="item in tokenTrxs"
                      class="media-body"
                      :key="item._id"
                    >
                      <i class="fa fa-caret-right"></i>
                      <span class=""><b>From</b> </span>
                      <ItemAddress
                        :address="item.from"
                        :info="item.fromInfo"
                        style="margin-start: 0.3rem; margin-end: 0.3rem"
                      />
                      <span class="mr-1"><b>To</b> </span>
                      <ItemAddress
                        :address="item.to"
                        :info="item.toInfo"
                        style="margin-start: 0.3rem; margin-end: 0.3rem"
                      />
                      <span class="mr-1"> <b>For</b> </span>
                      <span class="mr-1">
                        <!-- HRC-721 TokenID -->
                        <span
                          v-if="item.ERC == 721"
                          style="margin-start: 0.3rem; margin-end: 0.3rem"
                          ><strong>RCH-721 TokenID[</strong>{{ item.value
                          }}<strong>]</strong></span
                        >
                        <span
                          v-else-if="item.ERC == 2 || item.ERC == 20"
                          style="margin-start: 0.3rem; margin-end: 0.3rem"
                          ><strong>RCH-20[</strong>
                          <!-- {{ formatNumber(item.value, 4) }} -->
                          {{ item.value }}
                          <strong>]</strong></span
                        >
                        <span
                          v-else
                          style="margin-start: 0.3rem; margin-end: 0.3rem"
                          ><strong>Token[</strong>
                          <!-- {{ formatNumber(item.value, 4)}} -->
                          {{ item.value }}
                          <strong>]</strong></span
                        >
                      </span>
                      <ItemToken
                        :logo="item.token.logo"
                        :address="item.contract"
                        :tokenName="item.token.tokenName"
                        :symbol="item.token.symbol"
                      />
                    </div>
                  </el-scrollbar>
                </div>
              </div>
              <hr v-if="tokenTrxs.length > 0" class="hr-space" />

              <!-- coin inner transfer -->
              <div v-if="coinInners.length > 0" class="row">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <i
                    class="
                      fal
                      fa-question-circle
                      text-secondary
                      d-none d-sm-inline-block
                      mr-1
                    "
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="List of token transferred in the transaction."
                  ></i
                  >Internal Txns:
                  <span class="badge badge-pill badge-secondary align-midle">{{
                    coinInners.length
                  }}</span>
                </div>
                <div class="col-md-9">
                  <!-- inner transfers -->
                  <el-scrollbar max-height="200px">
                    <div
                      v-for="item in coinInners"
                      class="media-body"
                      :key="item._id"
                    >
                      <i class="fa fa-caret-right"></i>
                      <i
                        class="fa fa-check-circle"
                        style="margin-end: 0.3rem"
                        :class="{
                          'text-success': item.status,
                          'text-danger': !item.status,
                        }"
                      ></i>
                      <span class=""
                        ><b>[{{ item.tracer }}] </b>
                      </span>
                      <span class=""><b>From</b> </span>
                      <ItemAddress
                        :address="item.from"
                        :info="item.fromInfo"
                        style="margin-start: 0.3rem; margin-end: 0.3rem"
                      />
                      <span class="mr-1"><b>To</b> </span>
                      <ItemAddress
                        :address="item.to"
                        :info="item.toInfo"
                        style="margin-start: 0.3rem; margin-end: 0.3rem"
                      />
                      <span class="mr-1">
                        <!-- HRC-721 TokenID -->
                        <span style="margin-start: 0.3rem; margin-end: 0.3rem"
                          ><strong>Value[</strong>{{ item.value }}
                          {{ getName("Common", "Unit") }}<strong>]</strong>
                        </span>
                      </span>
                    </div>
                  </el-scrollbar>
                </div>
              </div>
              <hr v-if="coinInners.length > 0" class="hr-space" />

              <div class="row align-items-center mn-3">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <i
                    class="
                      fal
                      fa-question-circle
                      text-secondary
                      d-none d-sm-inline-block
                      mr-1
                    "
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="The value being transacted in HT and fiat value. Note: You can click the fiat value (if available) to see historical value at the time of transaction."
                  ></i
                  >Value:
                </div>
                <div class="col-md-9">
                  <span id="ContentPlaceHolder1_spanValue"
                    ><span
                      data-toggle="tooltip"
                      title="The amount of HT to be transferred to the recipient with the transaction"
                      ><span
                        class="
                          u-label u-label--value u-label--secondary
                          text-dark
                          rounded
                          mr-1
                        "
                        >{{ transactionData.value }}
                        {{ getName("Common", "Unit") }}</span
                      >
                    </span></span
                  >
                </div>
              </div>

              <hr class="hr-space" />
              <div id="ContentPlaceHolder1_collapseContent" class="collapse">
                <div class="row align-items-center">
                  <div
                    class="
                      col-md-3
                      font-weight-bold font-weight-sm-normal
                      mb-1 mb-md-0
                    "
                  >
                    <i
                      class="
                        fal
                        fa-question-circle
                        text-secondary
                        d-none d-sm-inline-block
                        mr-1
                      "
                      data-container="body"
                      data-toggle="popover"
                      data-placement="top"
                      data-original-title=""
                      title=""
                      data-content="The exact units of gas that was used for the transaction."
                    ></i
                    >Gas Used:
                  </div>
                  <div class="col-md-9">
                    <span
                      id="ContentPlaceHolder1_spanGasUsedByTxn"
                      title=""
                      data-toggle="tooltip"
                      >{{ transactionData.gasUsed }}</span
                    >
                  </div>
                </div>

                <hr class="hr-space" />

                <div class="row align-items-center">
                  <div
                    class="
                      col-md-3
                      font-weight-bold font-weight-sm-normal
                      mb-1 mb-md-0
                    "
                  >
                    <i
                      class="
                        fal
                        fa-question-circle
                        text-secondary
                        d-none d-sm-inline-block
                        mr-1
                      "
                      data-container="body"
                      data-toggle="popover"
                      data-placement="top"
                      data-original-title=""
                      title=""
                      data-content="Cost per unit of gas specified for the transaction, in HT and Gwei. The higher the gas price the higher chance of getting included in a block."
                    ></i
                    >Gas Price:
                  </div>
                  <div class="col-md-9">
                    <span
                      id="ContentPlaceHolder1_spanGasPrice"
                      title=""
                      data-toggle="tooltip"
                      >{{ transactionData.gasPrice }} wei</span
                    >
                  </div>
                </div>

                <hr class="hr-space" />

                <div class="row mn-3">
                  <div
                    class="
                      col-md-3
                      font-weight-bold font-weight-sm-normal
                      mb-1 mb-md-0
                    "
                  >
                    <i
                      class="
                        fal
                        fa-question-circle
                        text-secondary
                        d-none d-sm-inline-block
                        mr-1
                      "
                      data-container="body"
                      data-toggle="popover"
                      data-placement="top"
                      data-original-title=""
                      title=""
                      data-content="Sequential running number for an address, beginning with 0 for the first transaction. For example, if the nonce of a transaction is 10, it would be the 11th transaction sent from the sender&#39;s address."
                    ></i
                    >Nonce
                  </div>
                  <div class="col-md-9">
                    <span data-toggle="tooltip" title="">{{
                      transactionData.nonce
                    }}</span>
                  </div>
                </div>

                <hr class="hr-space" />

                <div class="row">
                  <div
                    class="
                      col-md-3
                      font-weight-bold font-weight-sm-normal
                      mb-1 mb-md-0
                    "
                  >
                    <i
                      class="
                        fal
                        fa-question-circle
                        text-secondary
                        d-none d-sm-inline-block
                        mr-1
                      "
                      data-container="body"
                      data-toggle="popover"
                      data-placement="top"
                      data-original-title=""
                      title=""
                      data-content="Additional information that is required for the transaction."
                    ></i
                    >Input Data:
                  </div>
                  <div class="col-md-9">
                    <div id="rawtab" data-target-group="inputDataGroup">
                      <span data-toggle="tooltip" title="">
                        <textarea
                          readonly
                          spellcheck="false"
                          class="
                            form-control
                            bg-light
                            text-secondary text-monospace
                            p-3
                          "
                          v-model="transactionData.input"
                          rows="5"
                        />
                      </span>
                      <div
                        id="ContentPlaceHolder1_btnconvert222"
                        class="d-flex align-items-center mt-2"
                      >
                        <div class="position-relative mr-2">
                          <span class="dropdown">
                            <div
                              class="dropdown-menu"
                              aria-labelledby="btnconvert222_1"
                            ></div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr class="hr-space" />
              </div>

              <span id="ContentPlaceHolder1_collapsedLink_span">
                <a
                  class="d-block collapsed"
                  id="collapsedLink"
                  href="#ContentPlaceHolder1_collapseContent"
                  data-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="ContentPlaceHolder1_collapseContent"
                >
                  <span>
                    Click to see <span class="card-arrow-more">More</span>
                    <span class="card-arrow-less">Less</span>
                    <span class="card-btn-arrow card-btn-arrow--up ml-2">
                      <span class="fas fa-arrow-up small"></span>
                    </span>
                  </span>
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <div id="push"></div>
</template>
<script>
import { getName } from "../../public/assets/js/lang.js";
import { PostNet, PostNetUrl } from "../../public/assets/js/myRequest.js";
import { importJS, getUrlKey } from "../../src/assets/js/utils.js";
import { formatNumber } from "../../public/assets/js/utils.js";
import route from "../router/index";
import ItemAddress from "./components/itemAddress.vue";
import ItemToken from "./components/item_token.vue";
export default {
  name: "TxDetail",
  data() {
    return {
      hash: "",
      transactionData: {},
      showTime: "",
      interval: null,
      loading: false,
      tokenTrxs: [],
      coinInners: [],
    };
  },
  components: {
    ItemAddress,
    ItemToken,
  },
  mounted() {
    this.hash = getUrlKey("h");
    this.loading = true;
    PostNet.PostWith(
      PostNetUrl.transactionDetail,
      (info) => {
        this.loading = false;
        this.transactionData = info.data;
        this.$nextTick(function () {
          importJS("assets/js/header/js-clipboard.js");
        });
      },
      (err) => {
        this.loading = false;
      },
      { hash: this.hash }
    );

    PostNet.PostWith(
      PostNetUrl.erc20TokenInnerTrxsByHash,
      (info) => {
        this.tokenTrxs = info.data;
      },
      (err) => {},
      { hash: this.hash }
    );
    this.getCoinInnerTxns();
    this.interval = setInterval(() => {
      this.caluetTime();
    }, 1000);
  },
  beforeUnmount() {
    if (this.interval != null) {
      clearInterval(this.interval);
    }
  },
  methods: {
    getName(str1, str2) {
      return getName(str1, str2);
    },
    formatDate(time, fmt) {
      if (time == 0) {
        return "";
      }
      time = time * 1000;
      let date = new Date(time);
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      let o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : this.padLeftZero(str)
          );
        }
      }
      return fmt;
    },
    padLeftZero(str) {
      return ("00" + str).substr(str.length);
    },
    formatNumber(number, decimail) {
      return formatNumber(number, decimail);
    },
    caluetTime() {
      if (!this.transactionData) {
        return;
      }
      try {
        if (!isNaN(this.transactionData.timestamp)) {
          const myDate = new Date();
          const now = myDate.getTime();
          const dis = now - this.transactionData.timestamp * 1000;
          var t = 0;
          if (dis < 0) {
            this.showTime = 0 + " " + getName("Home", "sec");
          } else if (dis < 60000) {
            t = parseInt(dis / 1000);
            this.showTime = t + " " + getName("Home", t > 1 ? "secs" : "sec");
          } else if (dis < 60 * 1000 * 60) {
            t = parseInt(dis / 1000 / 60);
            this.showTime = t + " " + getName("Home", t > 1 ? "mins" : "min");
          } else if (dis < 24 * 60 * 1000 * 60) {
            t = parseInt(dis / 1000 / 60 / 60);
            this.showTime = t + " " + getName("Home", t > 1 ? "hours" : "hour");
          } else if (dis < 365 * 24 * 60 * 1000 * 60) {
            t = parseInt(dis / 1000 / 60 / 60 / 24);
            this.showTime = t + " " + getName("Home", t > 1 ? "days" : "day");
          } else {
            t = parseInt(dis / 1000 / 60 / 60 / 24 / 365);
            this.showTime = t + " " + getName("Home", t > 1 ? "years" : "year");
          }
          this.showTime = this.showTime + " " + getName("Home", "ago");
        }
      } catch (e) {
        console.log(e);
      }
    },
    showBlock(blockNum) {
      route.push({
        path: "/block",
        query: {
          b: blockNum,
        },
      });
    },

    getCoinInnerTxns() {
      if (!this.hash) {
        return;
      }
      PostNet.PostWith(
        PostNetUrl.coinInners,
        (info) => {
          this.coinInners = info.data.data;
        },
        (err) => {},
        { hash: this.hash }
      );
    },
  },
};
</script>
